const Menu = [
  {
    path: "/dashboard",
    icon: "fa fa-th",
    title: "Dashboard",
  },

  {
    path: "#",
    icon: "fa fa-hdd",
    title: "Data Master",
    children: [
      { path: "/master-group", title: "Master Group" },
      { path: "/master-jenis", title: "Master Jenis" },
      { path: "/master-gudang", title: "Master Gudang" },
      { path: "/master-baki", title: "Master Baki" },
      { path: "/master-bandrol", title: "Master Bandrol" },
      // { path: '#', title: 'Master Pelanggan Non Member' },
      { path: "/master-sales", title: "Master Sales" },
      { path: "/master-marketplace", title: "Master Marketplace" },
      { path: '/master-tukang', title: 'Master Tukang' },
      { path: "/master-kode-transaksi", title: "Master Kode Transaksi" },
      { path: "/master-kondisi-barang", title: "Master Kondisi Barang" },
      { path: "/master-kondisi-pembelian", title: "Master Kondisi Pembelian" },
      { path: "/master-bank", title: "Master Bank" },
      { path: "/master-rekening", title: "Master Rekening" },
      { path: "/master-data-hadiah", title: "Master Hadiah" },
      { path: "/master-parameter-point", title: "Parameter Point" },
      { path: "/master-parameter-harga-emas", title: "Parameter Harga Emas" },
      // {
      //   path: "#",
      //   title: "Master Berlian",
      //   type: "BERLIAN_MODULE",
      //   children: [
      //     { path: "/master-batu-shape", title: "Batu Shape" },
      //     { path: "/master-batu-color", title: "Batu Color" },
      //     { path: "/master-batu-clarity", title: "Batu Clarity" },
      //     { path: "/master-batu-fluorencence", title: "Batu Fluorencence" },
      //     { path: "/master-batu-symetry", title: "Batu Symetry" },
      //     { path: "/master-batu-polish", title: "Batu Polish" },
      //     { path: "/master-batu-cutgrade", title: "Batu Cutgrade" },
      //     //  { path: "/master-batu-berlian", title: "Batu Berlian" },
      //     { path: "/jenis-batu", title: "Jenis Batu" },
      //   ],
      // },
      { path: '/master-parameter-rugi-nota', title: 'Parameter Rugi Nota' },
    ],
  },

  {
    path: "#",
    icon: "fa fa-filter",
    title: "Sepuhan",
    type: "SEPUHAN_MODULE",
    children: [
      {
        path: "/sortir-pembelian",
        title: "Sortir Pembelian",
        type: "SEPUHAN_MODULE",
      },
    ],
  },

  {
    path: "#",
    icon: "fa fa-archive",
    title: "Barang",
    children: [
      {
        path: "/penerimaan-barang",
        title: "Penerimaan Barang",
        type: "SEMI_GROSIR_MODULE",
      },
      { path: "/tambah-data-barang", title: "Tambah Data Barang" },
      { path: "/data-barang", title: "Data Barang" },
      // { path: "/terima-barang", title: "Terima Barang" },
      { path: "/hancur-data-barang", title: "Hancur Data Barang" },
      { path: "/pindah-barang", title: "Pindah Barang" },
      { path: "/pindah-barang-satu-baki", title: "Pindah Barang Satu Baki" },
      { path: "/timbang-baki", title: "Timbang Baki" },
    ],
  },
  {
    path: "#",
    icon: "fa fa-shopping-cart",
    title: "Penjualan",
    children: [
      { path: "/transaksi-penjualan", title: "Transaksi Penjualan" },
      { path: "/lihat-penjualan", title: "Lihat Penjualan" },
      { path: "/batal-penjualan", title: "Batal Penjualan" },
      { path: "/lihat-titipan", title: "Lihat Titipan" },
    ],
  },
  {
    path: "#",
    icon: "fa fa-boxes",
    title: "Pesanan",
    children: [
      { path: "/transaksi-pesanan", title: "Transaksi Pesanan" },
      { path: "/lihat-pesanan", title: "Lihat Pesanan" },
    ],
  },

  {
    path: "#",
    icon: "fa fa-shopping-bag",
    title: "Pembelian",
    children: [
      { path: "/transaksi-pembelian", title: "Transaksi Pembelian" },
      { path: "/lihat-pembelian", title: "Lihat Pembelian" },
    ],
  },
  {
    path: "/import-data",
    icon: "fa fa-upload",
    title: "Import Data",
  },
  {
    path: "#",
    icon: 'ion ion-md-build fa-2x fa-fw pull-left m-r-10 text-black-lighter"',
    title: "Service",
    children: [
      { path: "/transaksi-services", title: "Transaksi Service" },
      { path: "/lihat-services", title: "Lihat Services" },
    ],
  },

  {
    path: "#",
    icon: "fa fa-hand-holding-usd",
    title: "Hutang",
    children: [
      { path: "/transaksi-hutang", title: "Transaksi Hutang" },
      { path: "/lihat-hutang", title: "Lihat Hutang" },
    ],
  },

  {
    path: "/uang-kas",
    icon: "fas fa-money-bill-alt",
    title: "Uang Kas",
    // children: [
    // { path: "/lihat-uang-kas", title: "Uang Kas" },
    // { path: "/tambah-uang-kas", title: "Tambah Uang Kas" },
    // { path: "/ambil-uang-kas", title: "Ambil Uang Kas" },
    // ],
  },
  {
    path: "#",
    icon: "fas fa-desktop",
    title: "Validasi",
    children: [
      { path: "/validasi-jual-beli", title: "Validasi" },
      { path: "/validasi-hutang", title: "Validasi Hutang" },
      // { path: '/validasi-titipan', title: 'Validasi Titipan' },
    ],
  },

  {
    path: "#",
    icon: "fas fa-folder-open",
    title: "Laporan",
    children: [
      {
        path: "#",
        title: "Laporan Barang",
        children: [
          {
            path: "/laporan-terima-barang-supplier",
            title: "Laporan Terima Barang Supplier",
            type: "SEMI_GROSIR_MODULE",
          },
          { path: "/laporan-barang-detail", title: "Laporan Barang Detail" },
          { path: "/laporan-tambah-barang", title: "Laporan Tambah Barang" },
          { path: "/laporan-timbang-baki", title: "Laporan Timbang Baki" },
          { path: "/laporan-hancur-barang", title: "Laporan Hancur Barang" },
          { path: "/laporan-pindah-barang", title: "Laporan Pindah Barang" },
          { path: "/laporan-barang-sumary", title: "Laporan Barang Summary" },
          // {
          //   path: '#', title: 'Laporan Terima Barang',
          //   children: [
          //     { path: '/laporan-terima-barang', title: 'Laporan Terima Barang', },
          //     { path: '/laporan-barang-detail', title: 'Laporan Barang Detail', }
          //   ]
          // },
        ],
      },
      {
        path: "/laporan-pesanan",
        title: "Laporan Pesanan",
      },
      {
        path: "#",
        title: "Laporan Penjualan",
        children: [
          {
            path: "/laporan-penjualan-margin",
            title: "Laporan Penjualan Margin",
          },
          { path: "/laporan-titip-uang", title: "Laporan Titip Uang" },
          {
            path: "/laporan-stock-jual",
            title: "Laporan Stock Jual",
          },
          {
            path: "/laporan-transaksi-penjualan",
            title: "Laporan Transaksi Penjualan",
          },
          {
            path: "/laporan-penjualan-marketplace",
            title: "Laporan Penjualan Marketplace",
          },
          {
            path: "/laporan-barang-tukar",
            title: "Laporan Barang Tukar",
          },
          {
            path: "/laporan-batal-penjualan",
            title: "Laporan Batal Penjualan",
          },
          {
            path: "/laporan-penjualan-sales",
            title: "Laporan Penjualan Sales",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Pembelian",
        children: [
          {
            path: "/laporan-transaksi-pembelian",
            title: "Laporan Transaksi Pembelian",
          },
          {
            path: "/laporan-stock-pembelian",
            title: "Laporan Stock Pembelian",
          },
          {
            path: "/laporan-transaksi-pembelian-history",
            title: "Laporan History Transaksi Pembelian",
          },
          {
            path: "/laporan-batal-pembelian",
            title: "Laporan Batal Pembelian",
          },
          {
            path: "/laporan-pembelian-sales",
            title: "Laporan Pembelian Sales",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Service",
        children: [
          { path: "/laporan-services", title: "Laporan Transaksi Service" },
          { path: "/laporan-batal-service", title: "Laporan Batal Service" },
          { path: "/laporan-service-masuk", title: "Laporan Service (Masuk)" },
          {
            path: "/laporan-service-selesai",
            title: "Laporan Service (Selesai)",
          },
          { path: "/laporan-service-ambil", title: "Laporan Service (Ambil)" },
        ],
      },

      {
        path: "#",
        title: "Laporan Hutang",
        children: [
          {
            path: "/laporan-transaksi-hutang",
            title: "Laporan Transaksi Hutang",
          },
          { path: "/laporan-stock-hutang", title: "Laporan Stock Hutang" },
          { path: "/laporan-hutang-lunas", title: "Laporan Hutang Lunas" },
          { path: "/laporan-batal-hutang", title: "Laporan Batal Hutang" },
        ],
      },
      {
        path: "#",
        title: "Laporan Keuangan",
        children: [
          {
            path: "/laporan-transaksi-keuangan-cash",
            title: "Laporan Transaksi Keuangan Cash",
          },
          {
            path: "/laporan-keuangan-non-cash",
            title: "Laporan Keuangan Non Cash",
          },
        ],
      },
      // {
      //   path: "#",
      //   title: "Laporan Berlian",
      //   type: "BERLIAN_MODULE",
      //   children: [
      //     {
      //       path: "/laporan-buku-berlian",
      //       title: "Laporan Buku Berlian",
      //       type: "BERLIAN_MODULE",
      //     },
      //   ],
      // },
      // {
      //   path: "#",
      //   title: "Laporan Member",
      //   children: [
      //     { path: "/laporan-daftar-member", title: "Laporan Daftar Member" },
      //   ],
      // },

      {
        path: "/laporan-otorisasi",
        title: "Laporan Otorisasi",
      },
      {
        path: "/laporan-eod",
        title: "Laporan EOD",
      },
      // {
      //   path: "/laporan-authorization",
      //   title: "Laporan Otorisasi",
      // },
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Manage Users",
    children: [
      { path: "/data-users", title: "Data Users" },
      { path: "/hak-akses-users", title: "Hak Akses Users" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-cog",
    title: "Utility",
    children: [
      { path: "/settingsystem", title: "Setting System" },
      { path: "/cetak-barcode", title: "Cetak Barcode" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-business-time",
    title: "Stock Opname",
    children: [
      { path: "/stock-opname", title: "Stock Opname Barang" },
      {
        path: "/stock-opname-penerimaan",
        title: "Stock Opname Penerimaan Barang",
      },
    ],
  },
];

export default Menu;
